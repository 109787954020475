var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-printer")]), _vm._v("Printer Configuration ")], 1), _c('v-card-text', [_c('v-list', {
    staticClass: "transparent"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Cloud Print Setup")]), _c('v-list-item-subtitle', {
    staticClass: "secondary--text"
  }, [_vm._v(" LaserCAM Cloud can connect to your printers directly using a bridge software installed on your computer."), _c('br'), _vm._v(" When enabled, the default print dialog is bypassed allowing instant printing of documents and labels."), _c('br'), _c('br'), _vm._v(" To install: "), _c('ol', [_c('li', [_c('a', {
    attrs: {
      "href": "https://qz.io/download/",
      "target": "_blank"
    }
  }, [_vm._v("Download Qz Tray")]), _vm._v(" from their website.")]), _c('li', [_c('a', {
    attrs: {
      "href": "qz-tray/override.crt"
    }
  }, [_vm._v("Save this Certificate")]), _vm._v(" to C:\\Program Files\\QZ Tray\\override.crt")]), _c('li', [_vm._v("Right-click the QZ Tray Icon (in the taskbar notification area), and click "), _c('i', [_vm._v("Reload")])])])])], 1), _c('v-list-item-action', [_c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.CONNECTED === null
    },
    on: {
      "click": _vm.REFRESH
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-printer")]), _vm._v(_vm._s(_vm.CONNECTED ? 'REFRESH' : 'CONNECT'))], 1), _c('br'), _vm._v(" Print Devices: " + _vm._s(_vm.PRINTERS.length) + " ")], 1)])], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Documents")]), _c('v-list-item-subtitle', [_vm._v(" A4 document printer. ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.configType = "Document";
      }
    }
  }, [_vm._v("Manage")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Job Labels")]), _c('v-list-item-subtitle', [_vm._v(" Job labels (54 x 101mm recommended) to label product satchels. ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.configType = "Job Label";
      }
    }
  }, [_vm._v("Manage")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Shipping Labels")]), _c('v-list-item-subtitle', [_vm._v(" Shipping labels (104 x 159mm recommended) for outer packaging. ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.configType = "Shipping Label";
      }
    }
  }, [_vm._v("Manage")])], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.configType,
      "max-width": "400",
      "persistent": "",
      "scrollable": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Printer Setup: " + _vm._s(_vm.configType) + " ")]), _c('v-card-text', {
    staticStyle: {
      "max-height": "80vh"
    }
  }, [_c('v-row', {
    staticClass: "mt-2 pb-0"
  }, [_c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Printer",
      "items": _vm.PRINTERS
    },
    on: {
      "change": function change($event) {
        _vm.config.source = null;
      }
    },
    model: {
      value: _vm.config.printer,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "printer", $$v);
      },
      expression: "config.printer"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Paper Source",
      "items": _vm.paperSources
    },
    model: {
      value: _vm.config.printer_tray,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "printer_tray", $$v);
      },
      expression: "config.printer_tray"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Width",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.paper_width,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "paper_width", $$v);
      },
      expression: "config.paper_width"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Height",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.paper_height,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "paper_height", $$v);
      },
      expression: "config.paper_height"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Top Margin",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.margin_top,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "margin_top", $$v);
      },
      expression: "config.margin_top"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Left Margin",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.margin_left,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "margin_left", $$v);
      },
      expression: "config.margin_left"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Bottom Margin",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.margin_bottom,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "margin_bottom", $$v);
      },
      expression: "config.margin_bottom"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Right Margin",
      "placeholder": "(mm)",
      "type": "number"
    },
    model: {
      value: _vm.config.margin_right,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "margin_right", $$v);
      },
      expression: "config.margin_right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Copies",
      "type": "number"
    },
    model: {
      value: _vm.config.copies,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "copies", $$v);
      },
      expression: "config.copies"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "py-0 my-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Print Colour"
    },
    model: {
      value: _vm.config.colour,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "colour", $$v);
      },
      expression: "config.colour"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "py-0 my-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Print both sides"
    },
    model: {
      value: _vm.config.duplex,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "duplex", $$v);
      },
      expression: "config.duplex"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": _vm.test_print
    }
  }, [_vm._v("Test")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.configType = null;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }