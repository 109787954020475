<template>
  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>mdi-printer</v-icon>Printer Configuration
      </v-card-title>
      <v-card-text>
        <v-list class='transparent'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Cloud Print Setup</v-list-item-title>
              <v-list-item-subtitle class='secondary--text'>
                LaserCAM Cloud can connect to your printers directly using a bridge software installed on your computer.<br>
                When enabled, the default print dialog is bypassed allowing instant printing of documents and labels.<br><br>
                To install:
                <ol>
                  <li><a href='https://qz.io/download/' target='_blank'>Download Qz Tray</a> from their website.</li>
                  <li><a href='qz-tray/override.crt'>Save this Certificate</a> to C:\Program Files\QZ Tray\override.crt</li>
                  <li>Right-click the QZ Tray Icon (in the taskbar notification area), and click <i>Reload</i></li>
                </ol>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div class='text-center'>
              <v-btn :loading='CONNECTED===null' class='primary' @click='REFRESH'><v-icon class='mr-2'>mdi-printer</v-icon>{{CONNECTED?'REFRESH':'CONNECT'}}</v-btn>
              <br>
              Print Devices: {{PRINTERS.length}}
              </div>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Documents</v-list-item-title>
              <v-list-item-subtitle>
                A4 document printer.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn class='primary' @click='configType="Document"'>Manage</v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Job Labels</v-list-item-title>
              <v-list-item-subtitle>
                Job labels (54 x 101mm recommended) to label product satchels.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn class='primary' @click='configType="Job Label"'>Manage</v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Shipping Labels</v-list-item-title>
              <v-list-item-subtitle>
                Shipping labels (104 x 159mm recommended) for outer packaging.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn class='primary' @click='configType="Shipping Label"'>Manage</v-btn>
            </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog :value='configType' max-width='400' persistent scrollable>
      <v-card>
        <v-card-title class='primary white--text'>
          Printer Setup: {{configType}}
        </v-card-title>
        <v-card-text class='' style='max-height:80vh'>
          <v-row class='mt-2 pb-0'>
            <v-col cols=12 class='py-2'>
              <v-combobox
                outlined dense hide-details clearable class=''
                label='Printer'
                v-model='config.printer' :items='PRINTERS' @change='config.source = null'
              />
            </v-col>
            <v-col cols=12 class='py-2'>
              <v-combobox
                outlined dense hide-details clearable class=''
                label='Paper Source'
                v-model='config.printer_tray' :items='paperSources'
              />
            </v-col>
            <v-col cols=12><v-divider /></v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Width' placeholder='(mm)' type='number'
                v-model='config.paper_width'
              />
            </v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Height' placeholder='(mm)' type='number'
                v-model='config.paper_height'
              />
            </v-col>
            <v-col cols=12><v-divider /></v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Top Margin' placeholder='(mm)' type='number'
                v-model='config.margin_top'
              />
            </v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Left Margin' placeholder='(mm)' type='number'
                v-model='config.margin_left'
              />
            </v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Bottom Margin' placeholder='(mm)' type='number'
                v-model='config.margin_bottom'
              />
            </v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Right Margin' placeholder='(mm)' type='number'
                v-model='config.margin_right'
              />
            </v-col>
            <v-col cols=12><v-divider /></v-col>
            <v-col cols=6 class='py-2'>
              <v-text-field
                outlined dense hide-details clearable class=''
                label='Copies' type='number'
                v-model='config.copies'
              />
            </v-col>
            <v-col cols=12><v-divider /></v-col>
            <v-col cols=6 class='py-2'>

              <v-checkbox
                outlined dense hide-details class='py-0 my-0'
                label='Print Colour' v-model='config.colour'
              />
            </v-col>

            <v-col cols=6 class='py-2'>
              <v-checkbox
                outlined dense hide-details class='py-0 my-0'
                label='Print both sides' v-model='config.duplex'
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn class='secondary' @click="test_print">Test</v-btn>
          <v-spacer />
          <v-btn class='secondary' @click='configType=null'>Cancel</v-btn>
          <v-btn class='primary' @click='save'>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
//import LazyDropdown from '@/components/LazyDropdown'
export default {
  name: 'Template',

  components: {

  },

  data: () => ({
    loading: false,
    configType: null,
    config: {
      printer: null, printer_tray: null,
      paper_width: null, paper_height: null,
      margin_top: null, margin_left: null,
      margin_bottom: null, margin_right: null,
      copies: null, colour: null, duplex: null,
    }

  }),

  computed: {
    ...mapGetters('PRINTING', ['PRINTERS', 'CONNECTED', 'RAW_PRINTERS']),
    paperSources(){
      let printer = this.RAW_PRINTERS.find(p => p.name == this.config.printer);
      let sources = printer?.trays ?? [];
      if(typeof sources == "string") sources = [sources];
      return sources;
    }
  },

  watch: {
    async configType(type){
      this.config = JSON.parse(this._defaultConfig);
      this.GET_PRINTER_CONFIG(type).then(config => {
        this.config = { ...this.config, ...config };
      });
    }
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions('PRINTING', ['REFRESH', 'PRINT', 'SET_PRINTER_CONFIG', 'GET_PRINTER_CONFIG']),

    test_print(){
      this.API({ method: 'GET', endpoint: `account/printer_settings/test/${this.configType}` })
      .then(data => {
        this.PRINT({ data, config: {
          ...this.config,
          job_name: `Test Print: ${this.configType}`
        }});
      });
    },

    save(){
      this.SET_PRINTER_CONFIG({
        type: this.configType,
        config: JSON.parse(JSON.stringify(this.config))
      });
      this.configType = null;
    },

  },

  mounted() {
    this._defaultConfig = JSON.stringify(this.config);
  },

};
</script>
